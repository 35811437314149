import ms from 'ms';

export default {
  debug: false,
  showVersions: false,
  apiHealthPort: 10010,
  headers: {
    accountManagementToken: 'x-account-management-token',
    branding: 'x-august-branding',
  },
  pages: [
    {
      title: 'nav.home.title',
      info: 'nav.home.description',
      path: "/",
      icon: "home"
    },
    {
      title: 'nav.user.title',
      info: 'nav.user.description',
      path: "/user",
      icon: "person"
    },
    {
      title: 'nav.download.title',
      info: 'nav.download.description',
      path: "/download",
      icon: "cloud_download"
    },
    {
      title: 'nav.lost.title',
      info: 'nav.lost.description',
      path: "/lost",
      icon: "phonelink_lock"
    },
    {
      title: 'nav.reset.title',
      info: 'nav.reset.description',
      path: "/factoryresetrequest",
      icon: "sync"
    },
    {
      title: 'nav.delete.title',
      info: 'nav.delete.description',
      path: "/user/delete",
      icon: "delete"
    }
  ],
  s3: {
    region: '',
    bucket: '',
  },
  alertTimeoutInMS: ms('10 seconds'),
  sessionTimeout: {
    warningInMS: ms('59 minutes'),
    logoutInMS: ms('60 minutes'),
  },
  enableTranslations: true,
  signInWithApple: {
    clientId: 'com.august.account',

    // NOTE redirectURI is not used since we use the pop-up option, it is still required though
    // "If you use the pop-up option, your app sends the authorization information to Apple when the user clicks the Sign in with Apple button.
    // After Apple’s server processes the authorization request, it dispatches a Domain Object Model (DOM) event
    // containing the results of the authorization: AppleIDSignInOnSuccess on success, or AppleIDSignInOnFailure on failure."
    // @see https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
    redirectURI: 'https://account.august.com/auth/apple/callback',
  },
};
